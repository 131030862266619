html, body, div, span, applet, object, iframe, button,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: SpoqaHanSans;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  font-family: SpoqaHanSans;
}

body {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 1280px;
}

#__next {
  min-width: 1280px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

textarea:focus, input:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

input[type=text]::-ms-clear {
  display: none;
}

.minidaumInit {
  display: none;
}

.minidaumOriginal {
  height: 38px;
  background-color: #f6f6f6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.minidaumOriginal #minidaum {
  width: 978px;
  left: 50%;
  margin-left: -489px;
  height: 34px;
  visibility: hidden;
}
.minidaumOriginal.loaded #minidaum {
  visibility: visible;
}

.minidaumCustom {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10 !important;
  width: 218px;
  height: 48px !important;
  display: flex;
  align-items: flex-end;
}
.minidaumCustom #minidaum {
  position: relative;
  width: auto;
  min-width: 142px;
  max-width: 170px;
  height: 24px;
  display: flex;
  justify-content: flex-start;
  border-left: 1px solid #fff;
}
.minidaumCustom #minidaum .minidaum_gnb {
  padding: 0px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.minidaumCustom #minidaum #minidaumUser a {
  border: none;
}
.minidaumCustom #minidaum #minidaumUserLayer {
  top: 30px;
}
.minidaumCustom #minidaum #btnMinidaumLogin {
  background-size: 52px 24px;
  width: 52px;
  height: 24px;
  padding-top: 1px;
  line-height: 25px;
  background-position: -1px;
  float: left;
  overflow: hidden;
  vertical-align: bottom;
  text-indent: -9999px;
  background-image: url(//s.zigbang.com/daum-www/_next/static/login_default_color-0efcc61138241c9d09cf79f5b16e58e6.png);
}
.minidaumCustom #minidaum #btnMinidaumLogin:hover {
  background-image: url(//s.zigbang.com/daum-www/_next/static/login_hover_color-1a0c94f7773f1cd66f43e6cad5c50493.png);
}
.minidaumCustom #minidaum #minidaumService li a.minidaum_count:empty {
  display: none;
}
.minidaumCustom #minidaum #minidaumService .ico_dsvc_sitemap {
  display: none;
}
.minidaumCustom #minidaum #minidaumService #minidaumMail {
  position: relative;
  display: block;
  padding: 0 27px 0 21px;
  width: 20px;
  height: 20px;
  padding: 0;
  background-position: -3px -3px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  float: none;
  background-image: url(//s.zigbang.com/daum-www/_next/static/ic_mail_color-bfe9a1d0aef65ee206eb2ecd013aae87.png);
  background-size: 24px 24px;
  display: block;
}
.minidaumCustom #minidaum #minidaumService #minidaumMail:hover {
  background-image: url(//s.zigbang.com/daum-www/_next/static/ic_mail_hover_color-c3eef1e4c79cd4a1e9dab01e4f548244.png);
  background-size: 24px 24px;
}
.minidaumCustom #minidaum #minidaumService #minidaumMail #minidaumMailCnt {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 26px;
  height: 15px;
  padding: 0;
  margin: -6px 0 0 -1px;
  border-radius: 15px;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  font-family: arial, sans-serif;
  background: #ec5147;
  color: #ffffff;
  text-align: center;
  text-indent: 0;
  text-decoration: none;
}
.minidaumCustom #minidaum #minidaumService #minidaumMail span {
  display: none;
}
.minidaumCustom #minidaum #minidaumService #minidaumMailLayer {
  top: 30px;
}
.minidaumCustom #minidaum #minidaumService #minidaumMore {
  width: 20px;
  height: 20px;
  padding: 0;
  background-position: -3px -3px;
  background-image: url(//s.zigbang.com/daum-www/_next/static/ic_menu_color-9bb45eac9a79d0bcd100406d2bf42cd9.png);
  background-size: 24px 24px;
  display: block;
}
.minidaumCustom #minidaum #minidaumService #minidaumMore:hover {
  background-image: url(//s.zigbang.com/daum-www/_next/static/ic_menu_hover_color-d59dc4a12439940f35136d376040fa21.png);
}
.minidaumCustom #minidaum #minidaumService #minidaumMoreLayer {
  top: 30px;
}
.minidaumCustom #minidaum .minidaum_logo, .minidaumCustom #minidaum #minidaumRank, .minidaumCustom #minidaum .minidaum_news, .minidaumCustom #minidaum .minidaum_cafe, .minidaumCustom #minidaum .minidaum_shopping {
  display: none;
  background: none;
}

.swiper-wrapper {
  display: flex;
}
.swiper-wrapper .swiper-slide {
  flex: 0 0 auto;
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  padding: 4px 12px 7px;
  margin-top: 10px;
  background-color: rgba(51, 51, 51, 0.9);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-inner {
  min-width: 24px;
  font-size: 11px;
  height: auto;
  padding: 0;
  box-shadow: none;
  background: none;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: -4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: rgba(51, 51, 51, 0.9);
}
.dn-important {
  display: none !important;
}
